/* Login.module.css */
.container {
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
}

h2 {
    text-align: center;
    font-family: 'Lobster', cursive;
}

.logininfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

input {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

button {
    padding: 8px 16px;
    border-radius: 4px;
    background-color: #E9B529;
    color: #000;
    border: none;
    cursor: pointer;
    width: 100%;
}